const mainDomain = "https://core.agriquiz.live/";
// const mainDomain = "http://localhost:5001/";
const imageDomain = "https://core.agriquiz.live";
// const imageDomain = "http://localhost:5001";

const Constants = {
  imageDomain,
  getUrls: {
    getAllSubjects: mainDomain + "subject/list-dash",
    getAllTopics: mainDomain + "topic/list-dash",
    getAllLevels: mainDomain + "level/list-dash",
    getAllQuestions: mainDomain + "question/list-dash",
    getAllUsersList: mainDomain + "users/list-dash",
    getAllTestSeries: mainDomain + "testseries/list-dash",

    getDashboard: mainDomain + "emp/dashboard/dash",
    getAllTestimonials: mainDomain + "testimonial",
    getAllPopup: mainDomain + "popup",
    getAllJobApplications: mainDomain + "applyCareer",
    getAllContacts: mainDomain + "contacts",
    getAllBlogs: mainDomain + "blogs/list-dash",
    getAllWebContacts: mainDomain + "web-contact/list-dash",
  },
  postUrls: {
    createSubject: mainDomain + "subject/create",
    createTopic: mainDomain + "topic/create",
    createLevel: mainDomain + "level/create",
    createQuestion: mainDomain + "question/create",
    creatBulkImport: mainDomain + "question/bulk-import",
    createTestSerikes: mainDomain + "testseries/create",
    createBlogs: mainDomain + "blogs/create",

    createPopup: mainDomain + "popup/create",
    employerLogin: mainDomain + "admin/login",
    createTestimonial: mainDomain + "testimonial/create",
    jobsList: mainDomain + "emp/jobs/list",
    createNewJob: mainDomain + "emp/jobs/createJob",
    getjobWiseApplicants: mainDomain + "emp/application/list",
    getjobApplicantDetails: mainDomain + "applyCareer/list",
  },
  putUrls: {
    updateCareer: mainDomain + "subject/update/",

    updateJobs: mainDomain + "emp/jobs/updateJob",
    updateTestimonial: mainDomain + "testimonial/update/",
    jobPublish: mainDomain + "emp/jobs/jobPublish",
    applicationStatus: mainDomain + "emp/application/applicationStatus",
    popupStatus: mainDomain + "popup/status/",
  },
  deleteUrls: {
    deleteSubject: mainDomain + "subject/delete/",

    deletePopup: mainDomain + "popup/delete/",
    deleteTestimonial: mainDomain + "testimonial/delete/",
  },
};

export default Constants;
