import React, { Component } from "react";
import Constants from "Helpers/Constants";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import card from "assets/theme/components/card";
import cardContent from "assets/theme/components/card/cardContent";
import init from "Helpers/WindowToken";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Link, Navigate, redirect } from "react-router-dom";
import {
  Autocomplete,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  TextareaAutosize,
} from "@mui/material";
// import Textarea from "@mui/joy/Textarea";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const fd = new FormData();
export default class AddBlogs extends Component {
  state = {
    message: "",
    notification: false,
    blogs_poster: {},
    blogs_title: "",
    blog_content: "",
    blogs_for: "",
  };

  componentDidMount() {}

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDescription = (e) => {
    // console.log(e);
    this.setState({ blog_content: e });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    fd.append("blogs_title", this.state.blogs_title);
    fd.append("blogs_poster", this.state.blogs_poster);
    fd.append("blog_content", this.state.blog_content);
    fd.append("blog_type", this.state.blog_type);
    fd.append("blogs_for", this.state.blogs_for);
    // return;
    if (init() === "success") {
      axios
        .post(Constants.postUrls.createBlogs, fd)
        .then((resp) => {
          // console.log(resp);
          this.setState({
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "", redirect: true });
          }, 3000);
        })
        .catch((err) => {
          this.setState({
            notification: true,
            message: err.response.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "" });
          }, 5000);
        });
    }
  };

  render() {
    if (this.state.redirect === true) {
      return <Navigate to="/blogs" />;
    } else {
      return (
        <div>
          <div>
            <DashboardLayout>
              <DashboardNavbar />
              <MDBox pt={6} pb={3}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Create Subject
                    </MDTypography>
                  </MDBox>
                  {/* <Grid item sm={4} px={2} align="right">
                    <Link to="/admin/addNewJob">
                      <MDButton variant="contained" size="small" color="primary">
                        Create Job
                      </MDButton>
                    </Link>
                  </Grid> */}
                  <MDBox py={3} bgColor="white" px={2}>
                    <form onSubmit={this.handleSubmit}>
                      <Grid container sm={12} spacing={2}>
                        <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Blog Title"
                            name="blogs_title"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>

                        <Grid item sm={6}>
                          <TextField
                            type="file"
                            label="Blogs Poster"
                            name="blogs_poster"
                            focused
                            onChange={(e) => {
                              this.setState({
                                blogs_poster: e.target.files[0],
                              });
                            }}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>

                        <Grid item sm={6}>
                          <InputLabel>Select Blog Type</InputLabel>
                          <Select
                            value={this.state.blog_type}
                            onChange={(e) => this.setState({ blog_type: e.target.value })}
                            name="blog_type"
                            label="Select Subject"
                            // style={{
                            //   height: "45px",
                            // }}
                            required
                            style={{ width: "100%", height: "45px", color: "#111" }}
                          >
                            <MenuItem value="">Select Blog Type</MenuItem>

                            <MenuItem value="text" style={{ color: "#111" }}>
                              Text
                            </MenuItem>
                            <MenuItem value="video" style={{ color: "#111" }}>
                              Video
                            </MenuItem>
                          </Select>
                        </Grid>

                        <Grid item sm={6}>
                          <InputLabel>Select Blogs For</InputLabel>
                          <Select
                            value={this.state.blogs_for}
                            onChange={(e) => this.setState({ blogs_for: e.target.value })}
                            name="blogs_for"
                            label="Select Subject"
                            // style={{
                            //   height: "45px",
                            // }}
                            required
                            style={{ width: "100%", height: "45px", color: "#111" }}
                          >
                            <MenuItem value="">Select Subject</MenuItem>

                            <MenuItem value="web" style={{ color: "#111" }}>
                              Web
                            </MenuItem>
                            <MenuItem value="app" style={{ color: "#111" }}>
                              App
                            </MenuItem>
                            <MenuItem value="both" style={{ color: "#111" }}>
                              Both
                            </MenuItem>
                          </Select>
                        </Grid>

                        <Grid item sm={12}>
                          <SunEditor
                            // width="120%"
                            setDefaultStyle="font-family: Arial; font-size: 16px;"
                            setOptions={{
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize"],
                                ["paragraphStyle", "blockquote"],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                ],
                                ["fontColor", "hiliteColor"],
                                ["align", "list", "lineHeight"],
                                ["outdent", "indent"],

                                ["table", "horizontalRule", "link", "image", "video"],
                                // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                                ["imageGallery"], // You must add the "imageGalleryUrl".
                                // ["fullScreen", "showBlocks", "codeView"],
                                ["preview", "print"],
                                ["removeFormat"],

                                // ["save", "template"],
                                // '/', Line break
                              ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                              defaultTag: "div",
                              height: 120,
                              showPathLabel: false,
                              // font: sortedFontOptions
                            }}
                            name="blog_content"
                            setContents={this.state.blog_content}
                            onChange={this.handleDescription}
                          />
                        </Grid>
                      </Grid>

                      <Grid align="left" mt={2}>
                        <MDButton variant="contained" size="small" color="info" type="submit">
                          {this.state.loading ? "Creating..." : "Create"}
                        </MDButton>
                      </Grid>
                    </form>
                  </MDBox>
                  <>
                    <MDSnackbar
                      color="success"
                      icon="notifications"
                      title="Pro-Rider Career"
                      content={this.state.message}
                      dateTime="Just now"
                      open={this.state.notification}
                      //   close={toggleSnackbar}
                    />
                  </>
                </Card>
              </MDBox>
            </DashboardLayout>
          </div>
        </div>
      );
    }
  }
}
