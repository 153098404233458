import React, { Component } from "react";
import Constants from "Helpers/Constants";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import card from "assets/theme/components/card";
import cardContent from "assets/theme/components/card/cardContent";
import init from "Helpers/WindowToken";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Card, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { Link, Navigate } from "react-router-dom";

export default class WebContacts extends Component {
  state = {
    allJobs: [],
    message: "",
    notification: false,
    noticolor: "success",
    per_pge: 20,
    page_no: 1,
    redirect: false,
  };
  componentDidMount() {
    this.getAllWebContacts();
  }

  getAllWebContacts = () => {
    if (init() === "success") {
      // console.log("called");
      axios
        .get(Constants.getUrls.getAllWebContacts)
        .then((resp) => {
          console.log(resp);
          if (resp.data.status === "success") {
            this.setState({
              allJobs: resp.data.data.docs,
              notification: true,
              message: resp.data.message,
            });
            setTimeout(() => {
              this.setState({
                notification: false,
                message: "",
              });
            }, 2500);
          } else {
            this.setState({ notification: true, message: resp.data.message });
          }
        })
        .catch((err) => {
          //   console.log(err);
          this.setState({
            notification: true,
            message: err.response?.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              message: "",
            });
          }, 2500);
        });
    } else {
      window.location.assign("/signin");
    }
  };

  handleDelete = (id) => {
    if (init() === "success") {
      axios
        .delete(Constants.deleteUrls.deleteSubject + id)
        .then((resp) => {
          if (resp.data.status === "success") {
            this.setState({
              notification: true,
              message: resp.data.message,
            });
            setTimeout(() => {
              this.setState(
                {
                  notification: false,
                  message: "",
                },
                () => {
                  this.getAllWebContacts();
                }
              );
            }, 2500);
          }
        })
        .catch((err) => {
          //   console.log(err);
          this.setState({
            notification: true,
            message: err.response?.message,
          });
          setTimeout(() => {
            this.setState(
              {
                notification: false,
                message: "",
              },
              () => {
                this.getAllWebContacts();
              }
            );
          }, 2500);
        });
    } else {
      window.location.assign("/");
    }
  };

  render() {
    if (this.state.redirect === true) {
      return <Navigate to="/signin" replace={true} />;
    } else {
      return (
        <div>
          <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Web-Contact List
                  </MDTypography>
                </MDBox>
                <Grid item sm={4} px={2} align="right">
                  <Link to="/addLevel">
                    <MDButton variant="contained" size="small" color="primary">
                      Create
                    </MDButton>
                  </Link>
                </Grid>
                <MDBox bgColor="white">
                  <DataTable
                    table={{
                      columns: [
                        { Header: "serial", accessor: "serial", width: "6%" },
                        { Header: "name", accessor: "name" },
                        { Header: "email", accessor: "email" },
                        { Header: "mobile", accessor: "mobile" },
                        { Header: "message", accessor: "message" },
                      ],
                      rows: this.state.allJobs.map((job, index) => ({
                        serial: index + 1,
                        name: job?.name,
                        email: job?.email,
                        mobile: job?.mobile,
                        message: job?.message,

                        // action: (
                        //   <>
                        //     <div>
                        //       <Link
                        //         to={{
                        //           pathname: "/updatecareer/" + job._id,
                        //           state: { data: job },
                        //         }}
                        //       >
                        //         <MDButton variant="outlined" size="small" color="success">
                        //           Update
                        //         </MDButton>
                        //       </Link>
                        //     </div>
                        //     <div style={{ marginTop: "1rem" }}>
                        //       <MDButton
                        //         variant="outlined"
                        //         size="small"
                        //         color="error"
                        //         onClick={() => this.handleDelete(job._id)}
                        //       >
                        //         Delete
                        //       </MDButton>
                        //     </div>
                        //   </>
                        // ),
                      })),
                    }}
                  />
                </MDBox>
                <>
                  <MDSnackbar
                    color={this.state.noticolor}
                    icon="notifications"
                    title="AgriQuiz"
                    content={this.state.message}
                    dateTime="Just now"
                    open={this.state.notification}
                    //   close={toggleSnackbar}
                  />
                </>
              </Card>
            </MDBox>
          </DashboardLayout>
        </div>
      );
    }
  }
}
