import React, { Component } from "react";
import Constants from "Helpers/Constants";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import card from "assets/theme/components/card";
import cardContent from "assets/theme/components/card/cardContent";
import init from "Helpers/WindowToken";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Link, Navigate, redirect } from "react-router-dom";
import {
  Autocomplete,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  TextareaAutosize,
} from "@mui/material";
// import Textarea from "@mui/joy/Textarea";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const fd = new FormData();
export default class AddNewJob extends Component {
  state = {
    allJobs: [],
    message: "",
    notification: false,
    per_pge: 20,
    page_no: 1,
    profile_name: "",
    countrySearch: "",
    stateSearch: "",
    cityHubSearch: "",
    citySearch: "",
    allCountries: [],
    allStates: [],
    allCities: [],
    cityHubs: [],
    allQualifications: [],
    allCompanies: [],
    qualificationSearch: "",
    companySearch: "",
    min_qualification: 0,
    country: 0,
    state: 0,
    city: 0,
    city_hub: 0,
    company_id: 0,
    job_type: "",
    billing_cycle: "",
    onboarding_fee: "",
    onboarding_price: 0,
    application_link: "",
    audio_file: [],
    compensation: "",
    description: "",
    tags: "",
    redirect: false,
    delivery_item: "",
    per_packet_charge: "",
    daily_avg_volume: "",
    range_minimum: "",
    range_maximum: "",
    hours_shift: "",
    pan_card: "yes",
    aadhar_card: "yes",
    driving_license: "yes",
    bank_account: "yes",
    bike_requied: "yes",
    ev_on_rent: "no",
    chargeable_battery: 0,
    swappable_battery: 0,
    ev_security_deposit: "",
    job_offer: "",
  };

  componentDidMount() {
    this.getQualifications();
    this.getAllCompanies();
  }

  getQualifications = () => {
    axios.get(Constants.getUrls.getQualifications).then((resp) => {
      this.setState({ allQualifications: resp.data.data });
    });
  };

  getAllCompanies = () => {
    axios.get(Constants.getUrls.getAllCompanies).then((resp) => {
      this.setState({ allCompanies: resp.data.data });
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCountrySearch = (e) => {
    axios
      .get(Constants.getUrls.getCountries + "?text=" + e.target.value)
      .then((resp) => {
        this.setState({ allCountries: resp.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleState = (e, value) => {
    let countryId = this.state.allCountries.find((item) => item.country === value);
    if (countryId) {
      this.setState(
        {
          country: countryId.id,
        },
        () => {
          axios.get(Constants.getUrls.getStates + "?country=" + countryId.id).then((resp) => {
            this.setState({
              allStates: resp.data.data,
            });
          });
        }
      );
    }
  };

  handleStateSearch = (e) => {
    axios
      .get(
        Constants.getUrls.getStates + "?country=" + this.state.country + "&text=" + e.target.value
      )
      .then((resp) => {
        // console.log(resp);
        this.setState({
          allStates: resp.data.data,
        });
      });
  };

  handleCity = (e, value) => {
    let stateId = this.state.allStates.find((item) => item.state === value);
    console.log(stateId);
    if (stateId) {
      this.setState(
        {
          state: stateId.id,
        },
        () => {
          axios.get(Constants.getUrls.getCities + "?state_id=" + stateId.id).then((resp) => {
            this.setState({ allCities: resp.data.data });
          });
        }
      );
    }
  };

  handleCitySearch = (e) => {
    axios
      .get(
        Constants.getUrls.getCities + "?state_id=" + this.state.state + "&text=" + e.target.value
      )
      .then((resp) => {
        this.setState({ allCities: resp.data.data });
      });
  };

  handleCityHubs = (e, value) => {
    let cityId = this.state.allCities.find((item) => item.city === value);
    console.log(cityId.id, this.state.state);
    if (cityId) {
      this.setState(
        {
          city: cityId.id,
        },
        () => {
          axios
            .get(
              Constants.getUrls.getCityHubs +
                "?state_id=" +
                this.state.state +
                "&city=" +
                this.state.city
            )
            .then((resp) => {
              this.setState({ cityHubs: resp.data.data, state: this.state.state });
            });
        }
      );
    }
  };

  handleCityHubSearch = (e) => {
    axios
      .get(
        Constants.getUrls.getCityHubs + "?state_id=" + this.state.state + "&city=" + this.state.city
      )
      .then((resp) => {
        this.setState({ cityHubs: resp.data.data });
      });
  };

  handleCityHub = (e, value) => {
    const hubId = this.state.cityHubs.find((item) => item.hub_name === value);
    console.log(hubId.id, this.state.city, this.state.state);
    if (hubId) {
      this.setState({
        city_hub: hubId.id,
      });
    }
  };

  handleQualifications = (e) => {
    axios.get(Constants.getUrls.getQualifications + "?text=" + e.target.value).then((resp) => {
      this.setState({
        allQualifications: resp.data.data,
      });
    });
  };

  handleSelecteQualification = (e, value) => {
    const id = this.state.allQualifications.find((item) => item.qualification === value);
    if (id) {
      this.setState({ min_qualification: id.id });
    }
  };

  handlecompanies = (e) => {
    axios.get(Constants.getUrls.getQualifications + "?text=" + e.target.value).then((resp) => {
      this.setState({
        allQualifications: resp.data.data,
      });
    });
  };

  handleSelectedCompany = (e, value) => {
    const id = this.state.allCompanies.find((item) => item.company_name === value);
    if (id) {
      this.setState({ company_id: id.id });
    }
  };

  handleSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCompensation(content) {
    this.setState({
      compensation: content,
    });
  }
  handleDescription(content) {
    this.setState({
      description: content,
    });
  }

  handleFile = (e) => {
    // console.log(e.target.files);
    this.setState({
      audio_file: e.target.files[0],
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    fd.append("country", this.state.country);
    fd.append("state", this.state.state);
    fd.append("city", this.state.city);
    fd.append("city_hub", this.state.city_hub);
    fd.append("min_qualification", this.state.min_qualification);
    fd.append("company_id", this.state.company_id);
    fd.append("profile_name", this.state.profile_name);
    fd.append("job_type", this.state.job_type);
    fd.append("billing_cycle", this.state.billing_cycle);
    fd.append("onboarding_fee", this.state.onboarding_fee);
    if (this.state.onboarding_fee == "yes") {
      fd.append("onboarding_price", this.state.onboarding_price);
    }
    fd.append("application_link", this.state.application_link);
    fd.append("audio", this.state.audio_file);
    fd.append("compensation", this.state.compensation);
    fd.append("description", this.state.description);
    fd.append("tags", this.state.tags);
    fd.append("delivery_item", this.state.delivery_item);
    fd.append("per_packet_charge", this.state.per_packet_charge);
    fd.append("daily_avg_volume", this.state.daily_avg_volume);
    fd.append("range_minimum", this.state.range_minimum);
    fd.append("range_maximum", this.state.range_maximum);
    fd.append("hours_shift", this.state.hours_shift);
    fd.append("pan_card", this.state.pan_card);
    fd.append("aadhar_card", this.state.aadhar_card);
    fd.append("driving_license", this.state.driving_license);
    fd.append("bank_account", this.state.bank_account);
    fd.append("bike_required", this.state.bike_requied);
    fd.append("ev_on_rent", this.state.ev_on_rent);
    fd.append("chargeable_battery", this.state.chargeable_battery);
    fd.append("swappable_battery", this.state.swappable_battery);
    fd.append("ev_security_deposit", this.state.ev_security_deposit);
    fd.append("job_offer", this.state.job_offer);

    // return;
    if (init() === "success") {
      axios
        .post(Constants.postUrls.createNewJob, fd)
        .then((resp) => {
          // console.log(resp);
          this.setState({
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "", redirect: true });
          }, 3000);
        })
        .catch((err) => {
          this.setState({
            notification: true,
            message: err.response.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "" });
          }, 5000);
        });
    }
  };

  render() {
    if (this.state.redirect === true) {
      return <Navigate to="/jobs" />;
    } else {
      return (
        <div>
          <div>
            <DashboardLayout>
              <DashboardNavbar />
              <MDBox pt={6} pb={3}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Add New Topic
                    </MDTypography>
                  </MDBox>
                  {/* <Grid item sm={4} px={2} align="right">
                    <Link to="/admin/addNewJob">
                      <MDButton variant="contained" size="small" color="primary">
                        Create Job
                      </MDButton>
                    </Link>
                  </Grid> */}
                  <MDBox py={3} bgColor="white" px={2}>
                    <form onSubmit={this.handleSubmit}>
                      <div>
                        {" "}
                        <h4>Basic Job Details </h4>{" "}
                      </div>
                      <Grid container sm={12} spacing={2}>
                        {/* COUNTRY */}
                        <Grid item sm={6}>
                          <Autocomplete
                            freeSolo
                            autoComplete
                            autoHighlight
                            autoSelect
                            isOptionEqualToValue
                            value={this.state.allCountries.find((v) => v.selected)}
                            options={
                              this.state.allCountries
                                ? this.state.allCountries.map((option) => option.country)
                                : ""
                            }
                            onChange={this.handleState}
                            // clearOnEscape
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="countrySearch"
                                value={this.state.countrySearch}
                                onChange={this.handleCountrySearch}
                                variant="outlined"
                                label="Select Country"
                              />
                            )}
                          />
                        </Grid>
                        {/* STATE */}
                        <Grid item sm={6}>
                          <Autocomplete
                            freeSolo
                            autoComplete
                            autoHighlight
                            autoSelect
                            isOptionEqualToValue
                            options={
                              this.state.allStates
                                ? this.state.allStates.map((option) => option.state)
                                : ""
                            }
                            onChange={this.handleCity}
                            // clearOnEscape
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="stateSearch"
                                value={this.state.stateSearch}
                                onChange={this.handleStateSearch}
                                variant="outlined"
                                label="Select State"
                              />
                            )}
                          />
                        </Grid>
                        {/* CITY */}
                        <Grid item sm={6}>
                          <Autocomplete
                            freeSolo
                            autoComplete
                            autoHighlight
                            autoSelect
                            isOptionEqualToValue
                            options={
                              this.state.allCities
                                ? this.state.allCities.map((option) => option.city)
                                : ""
                            }
                            onChange={this.handleCityHubs}
                            // clearOnEscape
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="citySearch"
                                value={this.state.citySearch}
                                onChange={this.handleCitySearch}
                                variant="outlined"
                                label="Select City"
                                style={{
                                  padding: "0",
                                }}
                              />
                            )}
                          />
                        </Grid>
                        {/* CITY HUB */}
                        <Grid item sm={6}>
                          <Autocomplete
                            freeSolo
                            autoComplete
                            autoHighlight
                            autoSelect
                            // isOptionEqualToValue
                            options={
                              this.state.cityHubs
                                ? this.state.cityHubs.map((option) => option.hub_name)
                                : ""
                            }
                            onChange={this.handleCityHub}
                            // clearOnEscape
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="cityHubSearch"
                                value={this.state.cityHubSearch}
                                onChange={this.handleCityHubSearch}
                                variant="outlined"
                                label="Select City Hub"
                                style={{
                                  padding: "0",
                                }}
                              />
                            )}
                          />
                        </Grid>
                        {/* Qualifications */}
                        <Grid item sm={6}>
                          <Autocomplete
                            freeSolo
                            autoComplete
                            autoHighlight
                            autoSelect
                            // isOptionEqualToValue
                            options={
                              this.state.allQualifications
                                ? this.state.allQualifications.map((option) => option.qualification)
                                : ""
                            }
                            onChange={this.handleSelecteQualification}
                            // clearOnEscape
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="citySearch"
                                value={this.state.qualificationSearch}
                                onChange={this.handleQualifications}
                                variant="outlined"
                                label="Select Min Qualification"
                                style={{
                                  padding: "0",
                                }}
                              />
                            )}
                          />
                        </Grid>
                        {/* COMPANY LIST */}
                        <Grid item sm={6}>
                          <Autocomplete
                            freeSolo
                            autoComplete
                            autoHighlight
                            autoSelect
                            // isOptionEqualToValue
                            options={
                              this.state.allCompanies
                                ? this.state.allCompanies.map((option) => option.company_name)
                                : ""
                            }
                            onChange={this.handleSelectedCompany}
                            // clearOnEscape
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="citySearch"
                                value={this.state.companySearch}
                                onChange={this.handlecompanies}
                                variant="outlined"
                                label="Select Company"
                                style={{
                                  padding: "0",
                                }}
                              />
                            )}
                          />
                        </Grid>
                        {/* JOB Profile */}
                        <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Job Profile"
                            name="profile_name"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>
                        {/* Job Type */}
                        <Grid item sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Job Type</InputLabel>
                            <Select
                              value={this.state.job_type}
                              onChange={this.handleSelect}
                              label="Job Type"
                              name="job_type"
                              style={{
                                height: "45px",
                              }}
                              required
                            >
                              <MenuItem value="full_time">Full Time</MenuItem>
                              <MenuItem value="part_time">Part Time</MenuItem>
                              <MenuItem value="flexible">Flexible</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* Billing Cycle */}
                        <Grid item sm={6}>
                          <FormControl fullWidth>
                            <InputLabel> Billing Cycle</InputLabel>
                            <Select
                              value={this.state.billing_cycle}
                              label="Billing Cycle"
                              onChange={this.handleSelect}
                              name="billing_cycle"
                              style={{
                                height: "45px",
                              }}
                              required
                            >
                              <MenuItem value="weekly">Weekly</MenuItem>
                              <MenuItem value="15 Days">15 Days</MenuItem>
                              <MenuItem value="monthly">Monthly</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* OnBoarding Fee */}
                        <Grid item sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Onboarding Fee</InputLabel>
                            <Select
                              value={this.state.onboarding_fee}
                              onChange={this.handleSelect}
                              label="Onboarding Fee"
                              name="onboarding_fee"
                              style={{
                                height: "45px",
                              }}
                              required
                            >
                              <MenuItem value="yes">Yes</MenuItem>
                              <MenuItem value="no">No</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {this.state.onboarding_fee === "yes" && (
                          <Grid item sm={6}>
                            <TextField
                              type="text"
                              label="Onboarding Price"
                              name="onboarding_price"
                              onChange={this.handleInput}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </Grid>
                        )}
                        <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="External Application Link"
                            name="application_link"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>
                        {/* <Grid item sm={4}>
                          <TextField
                            type="file"
                            label="Audio File"
                            focused
                            name="audio_file"
                            onChange={this.handleFile}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid> */}
                        <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Add Tags (comma seperated)"
                            name="tags"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          {/* <InputLabel> Add Compensation</InputLabel> */}
                          {/* <TextField
                            type="text"
                            label="Compensation"
                            name="tags"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          /> */}
                          <textarea
                            name="compensation"
                            placeholder="Compensation"
                            onChange={this.handleInput}
                            rows="5"
                            cols={60}
                            style={{
                              padding: "10px",
                              border: "1px solid #d9d9d9",
                              borderRadius: "4px",
                              color: "14px",
                            }}
                          />
                        </Grid>

                        <Grid item sm={6}>
                          <textarea
                            name="description"
                            placeholder="Description"
                            onChange={this.handleInput}
                            rows="5"
                            cols={63}
                            style={{
                              padding: "10px",
                              border: "1px solid #d9d9d9",
                              borderRadius: "4px",
                              color: "14px",
                            }}
                          />
                        </Grid>
                      </Grid>
                      <div>
                        {" "}
                        <h4>Income Details </h4>{" "}
                      </div>
                      <Grid container sm={12} spacing={2} mt={1}>
                        <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Delivery Item"
                            name="delivery_item"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Per Package Charge"
                            name="per_packet_charge"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Daily Average Delivery"
                            name="daily_avg_volume"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            type="number"
                            label="Minimum Distance"
                            name="range_minimum"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            type="number"
                            label="Maximum Distance"
                            name="range_maximum"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Work Hours"
                            name="hours_shift"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <div>
                        {" "}
                        <h4>Documents Required </h4>{" "}
                      </div>
                      <Grid container sm={12} spacing={2} mt={1}>
                        {/* AAdhar Card */}
                        <Grid item sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Aadhar Card</InputLabel>
                            <Select
                              value={this.state.aadhar_card}
                              onChange={this.handleSelect}
                              label="Aadhar Card"
                              name="aadhar_card"
                              style={{
                                height: "45px",
                              }}
                              selected
                            >
                              <MenuItem value="yes">Yes</MenuItem>
                              <MenuItem value="no">No</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* Pan Card */}
                        <Grid item sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Pan Card</InputLabel>
                            <Select
                              value={this.state.pan_card}
                              onChange={this.handleSelect}
                              label="Pan Card"
                              name="pan_card"
                              style={{
                                height: "45px",
                              }}
                            >
                              <MenuItem value="yes">Yes</MenuItem>
                              <MenuItem value="no">No</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* Driving License */}
                        <Grid item sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Driving License</InputLabel>
                            <Select
                              value={this.state.driving_license}
                              onChange={this.handleSelect}
                              label="Driving License"
                              name="driving_license"
                              style={{
                                height: "45px",
                              }}
                            >
                              <MenuItem value="yes">Yes</MenuItem>
                              <MenuItem value="no">No</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* Bank Account */}
                        <Grid item sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Bank Account</InputLabel>
                            <Select
                              value={this.state.bank_account}
                              label="Bank Account"
                              onChange={this.handleSelect}
                              name="bank_account"
                              style={{
                                height: "45px",
                              }}
                            >
                              <MenuItem value="yes">Yes</MenuItem>
                              <MenuItem value="no">No</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* Bike Required */}
                        <Grid item sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>Bike Required</InputLabel>
                            <Select
                              value={this.state.bike_requied}
                              onChange={this.handleSelect}
                              label="Bike Required"
                              name="bike_requied"
                              style={{
                                height: "45px",
                              }}
                            >
                              <MenuItem value="yes">Yes</MenuItem>
                              <MenuItem value="no">No</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <div>
                        <h4>EV Bike and Offer</h4>
                      </div>

                      <Grid container sm={12} spacing={2} mt={1}>
                        <Grid item sm={6}>
                          <FormControl fullWidth>
                            <InputLabel>EV on Rent</InputLabel>
                            <Select
                              value={this.state.ev_on_rent}
                              onChange={this.handleSelect}
                              label="EV On Rent"
                              name="ev_on_rent"
                              style={{
                                height: "45px",
                              }}
                            >
                              <MenuItem value="yes">Yes</MenuItem>
                              <MenuItem value="no">No</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {this.state.ev_on_rent === "yes" && (
                          <>
                            <Grid item sm={6}>
                              <TextField
                                type="text"
                                label="Chargeable Battery Charges"
                                name="chargeable_battery"
                                onChange={this.handleInput}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <TextField
                                type="text"
                                label="Swappable Battery Charges"
                                name="chargeable_battery"
                                onChange={this.handleInput}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <TextField
                                type="text"
                                label="EV Security Deposit"
                                name="ev_security_deposit"
                                onChange={this.handleInput}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Job Offer"
                            name="job_offer"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid align="left" mt={2}>
                        <MDButton variant="contained" size="small" color="info" type="submit">
                          {this.state.loading ? "Creating..." : "Create"}
                        </MDButton>
                      </Grid>
                    </form>
                  </MDBox>
                  <>
                    <MDSnackbar
                      color="success"
                      icon="notifications"
                      title="Pro-Rider Jobs"
                      content={this.state.message}
                      dateTime="Just now"
                      open={this.state.notification}
                      //   close={toggleSnackbar}
                    />
                  </>
                </Card>
              </MDBox>
            </DashboardLayout>
          </div>
        </div>
      );
    }
  }
}
