import React, { Component } from "react";
import Constants from "Helpers/Constants";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import card from "assets/theme/components/card";
import cardContent from "assets/theme/components/card/cardContent";
import init from "Helpers/WindowToken";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Link, Navigate, redirect } from "react-router-dom";
import {
  Autocomplete,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  TextareaAutosize,
} from "@mui/material";
// import Textarea from "@mui/joy/Textarea";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const fd = new FormData();
export default class AddQuestions extends Component {
  state = {
    allJobs: [],
    message: "",
    notification: false,
    per_pge: 20,
    page_no: 1,

    redirect: false,
    allSubjects: [],
    alltopics: [],
    allLevels: [],
    level_id: "",
    topic_id: "",
    level_name: "",
    question_type: "",
    testimonial: "",
    subject_id: "",
    search: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: "",
  };

  componentDidMount() {
    this.getAllSubjects();
  }

  getAllSubjects = async () => {
    if (init() === "success") {
      await axios
        .get(Constants.getUrls.getAllSubjects + "?search=" + this.state.search)
        .then((resp) => {
          //   console.log(resp.data.data.docs);
          this.setState({
            allSubjects: resp.data.data.docs,
          });
        });
    }
  };

  handleTopic = async () => {
    if (init() === "success") {
      await axios
        .get(Constants.getUrls.getAllTopics + "?subject_id=" + this.state.subject_id)
        .then((resp) => {
          //   console.log(resp.data.data.docs);
          this.setState({
            alltopics: resp.data.data.docs,
          });
        });
    }
  };

  handleLevel = async () => {
    if (init() === "success") {
      await axios
        .get(
          Constants.getUrls.getAllLevels +
            "?subject_id=" +
            this.state.subject_id +
            "&topic_id=" +
            this.state.topic_id
        )
        .then((resp) => {
          //   console.log(resp.data.data.docs);
          this.setState({
            allLevels: resp.data.data.docs,
          });
        });
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      subject_id: this.state.subject_id,
      level_id: this.state.level_id,
      topic_id: this.state.topic_id,
      question_type: this.state.question_type,
      question: this.state.question,
      option1: this.state.option1,
      option2: this.state.option2,
      option3: this.state.option3,
      option4: this.state.option4,
      answer: this.state.answer,
    };

    if (init() === "success") {
      axios
        .post(Constants.postUrls.createQuestion, payload)
        .then((resp) => {
          // console.log(resp);
          this.setState({
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "", redirect: true });
          }, 3000);
        })
        .catch((err) => {
          this.setState({
            notification: true,
            message: err.response.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "" });
          }, 5000);
        });
    }
  };

  render() {
    if (this.state.redirect === true) {
      return <Navigate to="/questions" />;
    } else {
      return (
        <div>
          <div>
            <DashboardLayout>
              <DashboardNavbar />
              <MDBox pt={6} pb={3}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Create Questions
                    </MDTypography>
                  </MDBox>
                  {/* <Grid item sm={4} px={2} align="right">
                    <Link to="/admin/addNewJob">
                      <MDButton variant="contained" size="small" color="primary">
                        Create Job
                      </MDButton>
                    </Link>
                  </Grid> */}
                  <MDBox py={3} bgColor="white" px={2}>
                    <form onSubmit={this.handleSubmit}>
                      <Grid container sm={12} spacing={2}>
                        {/* Select Subject */}
                        <Grid item sm={6}>
                          <InputLabel>Select Subject</InputLabel>
                          <Select
                            value={this.state.subject_id}
                            onChange={(e) =>
                              this.setState({ subject_id: e.target.value }, () =>
                                this.handleTopic()
                              )
                            }
                            name="subject_id"
                            label="Select Subject"
                            // style={{
                            //   height: "45px",
                            // }}
                            required
                            style={{ width: "100%", height: "45px", color: "#111" }}
                          >
                            <MenuItem value="">Select Subject</MenuItem>
                            {this.state.allSubjects.length > 0 &&
                              this.state.allSubjects.map((item) => (
                                <MenuItem value={item._id} key={item._id} style={{ color: "#111" }}>
                                  {" "}
                                  {item.subject_name}{" "}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        {/* Select Topic */}
                        <Grid item sm={6}>
                          <InputLabel>Select Topic</InputLabel>
                          <Select
                            value={this.state.topic_id}
                            onChange={(e) =>
                              this.setState({ topic_id: e.target.value }, () => this.handleLevel())
                            }
                            name="topic_id"
                            label="Select Subject"
                            // style={{
                            //   height: "45px",
                            // }}
                            required
                            style={{ width: "100%", height: "45px", color: "#111" }}
                          >
                            <MenuItem value="">Select Subject</MenuItem>
                            {this.state.alltopics.length > 0 &&
                              this.state.alltopics.map((item) => (
                                <MenuItem value={item._id} key={item._id} style={{ color: "#111" }}>
                                  {" "}
                                  {item.topic_name}{" "}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>

                        {/* Select Level */}
                        <Grid item sm={6}>
                          <InputLabel>Select Level</InputLabel>
                          <Select
                            value={this.state.level_id}
                            onChange={(e) => this.setState({ level_id: e.target.value })}
                            name="level_id"
                            label="Select Subject"
                            // style={{
                            //   height: "45px",
                            // }}
                            required
                            style={{ width: "100%", height: "45px", color: "#111" }}
                          >
                            <MenuItem value="">Select Subject</MenuItem>
                            {this.state.allLevels.length > 0 &&
                              this.state.allLevels.map((item) => (
                                <MenuItem value={item._id} key={item._id} style={{ color: "#111" }}>
                                  {" "}
                                  {item.level_name}{" "}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        {/* Select Question Type */}
                        <Grid item sm={6}>
                          <InputLabel>Select Question Type</InputLabel>
                          <Select
                            value={this.state.question_type}
                            onChange={(e) => this.setState({ question_type: e.target.value })}
                            name="question_type"
                            label="Select Subject"
                            // style={{
                            //   height: "45px",
                            // }}
                            required
                            style={{ width: "100%", height: "45px", color: "#111" }}
                          >
                            <MenuItem value="easy">Easy</MenuItem>
                            <MenuItem value="medium">Medium</MenuItem>
                            <MenuItem value="hard">Hard</MenuItem>
                          </Select>
                        </Grid>

                        <Grid item sm={12}>
                          <InputLabel>Question</InputLabel>
                          <TextField
                            type="text"
                            // label="Subject Name"
                            name="question"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                            required
                          />
                        </Grid>

                        <Grid item sm={6}>
                          <InputLabel>Option 1</InputLabel>
                          <TextField
                            type="text"
                            // label="Subject Name"
                            name="option1"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                            required
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <InputLabel>Option2</InputLabel>
                          <TextField
                            type="text"
                            // label="Subject Name"
                            name="option2"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                            required
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <InputLabel>Option 3</InputLabel>
                          <TextField
                            type="text"
                            // label="Subject Name"
                            name="option3"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                            required
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <InputLabel>Option 4</InputLabel>
                          <TextField
                            type="text"
                            // label="Subject Name"
                            name="option4"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                            required
                          />
                        </Grid>

                        <Grid item sm={6}>
                          <InputLabel>Select Answer</InputLabel>
                          <Select
                            value={this.state.answer}
                            onChange={(e) => this.setState({ answer: e.target.value })}
                            name="answer"
                            label="Select Subject"
                            // style={{
                            //   height: "45px",
                            // }}
                            required
                            style={{ width: "100%", height: "45px", color: "#111" }}
                          >
                            <MenuItem value="option1">Option 1</MenuItem>
                            <MenuItem value="option2">Option 2</MenuItem>
                            <MenuItem value="option3">Option 3</MenuItem>
                            <MenuItem value="option4">Option 4</MenuItem>
                          </Select>
                        </Grid>

                        {/* <Grid item sm={6}>
                          <TextField
                            type="file"
                            label="Subject Poster"
                            name="subject_poster"
                            focused
                            onChange={(e) => {
                              this.setState({
                                subject_poster: e.target.files[0],
                              });
                            }}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid> */}

                        {/* <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Testimonial"
                            name="testimonial"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid> */}
                      </Grid>

                      <Grid align="left" mt={2}>
                        <MDButton variant="contained" size="small" color="info" type="submit">
                          {this.state.loading ? "Creating..." : "Create"}
                        </MDButton>
                      </Grid>
                    </form>
                  </MDBox>
                  <>
                    <MDSnackbar
                      color="success"
                      icon="notifications"
                      title="AgriQuiz Topics"
                      content={this.state.message}
                      dateTime="Just now"
                      open={this.state.notification}
                      //   close={toggleSnackbar}
                    />
                  </>
                </Card>
              </MDBox>
            </DashboardLayout>
          </div>
        </div>
      );
    }
  }
}
