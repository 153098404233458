import React, { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, BrowserRouter, redirect } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import Dashboard from "layouts/dashboard";
import AddNewJob from "AddViews/AddNewJob";
import Signin from "Auth/Signin";
import UpdateJob from "UpdateViews/UpdateJob";
import JobWiseApplicants from "./Views/JobWiseApplicants";
import ApplicantProfile from "./Views/ApplicantProfile";
import AddNewTestimonial from "AddViews/AddTestimonials";
import UpdateTestimonials from "UpdateViews/UpdateTestimonials";
import AddCareer from "AddViews/AddCareer";
import UpdateCareer from "UpdateViews/UpdateCareer";
import AddPopup from "AddViews/AddPopup";
import AddTopics from "AddViews/AddTopics";
import AddLevels from "AddViews/AddLevels";
import AddQuestions from "AddViews/AddQuestions";
import BulkImport from "AddViews/BulkImport";
import CreateTestSeries from "AddViews/CreateTestSeries";
import AddBlogs from "AddViews/AddBlogs";

export default function Main() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );
  return (
    <div>
      <Sidenav
        color={sidenavColor}
        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
        brandName="AgriQuiz"
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Configurator />
      {configsButton}
      <Routes>
        {/* <Route path="/" element={<Dashboard />} /> */}
        {getRoutes(routes)}
        <Route path="/addNewJob" element={<AddNewJob />} />
        <Route path="/updateJob/:id" element={<UpdateJob />} />
        <Route path="/jobApplicants/:id" element={<JobWiseApplicants />} />
        <Route path="/applicant-details/:id" element={<ApplicantProfile />} />
        <Route path="/addtestimonials" element={<AddNewTestimonial />} />
        <Route path="/updateTestimonial/:id" element={<UpdateTestimonials />} />
        <Route path="/addcareer" element={<AddCareer />} />
        <Route path="/addTopic" element={<AddTopics />} />
        <Route path="/addLevel" element={<AddLevels />} />
        <Route path="/addQuestion" element={<AddQuestions />} />
        <Route path="/add-bulk-question" element={<BulkImport />} />
        <Route path="/addTestseries" element={<CreateTestSeries />} />
        <Route path="/addnewblogs" element={<AddBlogs />} />
      </Routes>
    </div>
  );
}
