import React, { Component } from "react";
import Constants from "Helpers/Constants";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import card from "assets/theme/components/card";
import cardContent from "assets/theme/components/card/cardContent";
import init from "Helpers/WindowToken";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Link, Navigate, redirect } from "react-router-dom";
import {
  Autocomplete,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  TextareaAutosize,
} from "@mui/material";
// import Textarea from "@mui/joy/Textarea";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const fd = new FormData();
export default class UpdateCareer extends Component {
  state = {
    allJobs: [],
    message: "",
    notification: false,
    per_pge: 20,
    page_no: 1,
    profile_name: "",
    countrySearch: "",
    stateSearch: "",
    cityHubSearch: "",
    citySearch: "",
    allCountries: [],
    allStates: [],
    allCities: [],
    cityHubs: [],
    allQualifications: [],
    allCompanies: [],
    qualificationSearch: "",
    companySearch: "",
    min_qualification: 0,
    country: 0,
    state: 0,
    city: 0,
    city_hub: 0,
    company_id: 0,
    job_type: "",
    billing_cycle: "",
    onboarding_fee: "",
    onboarding_price: 0,
    application_link: "",
    audio_file: [],
    compensation: "",
    description: "",
    tags: "",
    redirect: false,
    delivery_item: "",
    per_packet_charge: "",
    daily_avg_volume: "",
    range_minimum: "",
    range_maximum: "",
    hours_shift: "",
    pan_card: "yes",
    aadhar_card: "yes",
    driving_license: "yes",
    bank_account: "yes",
    bike_requied: "yes",
    ev_on_rent: "no",
    chargeable_battery: 0,
    swappable_battery: 0,
    ev_security_deposit: "",
    job_offer: "",
    client_image: {},
    client_name: "",
    testimonial: "",
  };

  componentDidMount() {
    this.getSingleCareer();
  }

  getSingleCareer = async () => {
    if (init() === "success") {
      await axios
        .get(Constants.getUrls.getAllCareers + "/" + window.location.pathname.split("/")[2])
        .then((resp) => {
          console.log(resp);
          this.setState({
            client_image: resp.data.data.career_poster,
            client_name: resp.data.data.career,
            // testimonial: resp.data.data.content,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    fd.append("career", this.state.client_name);
    fd.append("image", this.state.client_image);
    // fd.append("content", this.state.testimonial);
    // return;
    if (init() === "success") {
      axios
        .post(Constants.postUrls.createCareer, fd)
        .then((resp) => {
          // console.log(resp);
          this.setState({
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "", redirect: true });
          }, 3000);
        })
        .catch((err) => {
          this.setState({
            notification: true,
            message: err.response.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "" });
          }, 5000);
        });
    }
  };

  render() {
    if (this.state.redirect === true) {
      return <Navigate to="/career" />;
    } else {
      return (
        <div>
          <div>
            <DashboardLayout>
              <DashboardNavbar />
              <MDBox pt={6} pb={3}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Create Career
                    </MDTypography>
                  </MDBox>
                  {/* <Grid item sm={4} px={2} align="right">
                    <Link to="/admin/addNewJob">
                      <MDButton variant="contained" size="small" color="primary">
                        Create Job
                      </MDButton>
                    </Link>
                  </Grid> */}
                  <MDBox py={3} bgColor="white" px={2}>
                    <form onSubmit={this.handleSubmit}>
                      <Grid container sm={12} spacing={2}>
                        <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Career Profile"
                            name="client_name"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>

                        <Grid item sm={6}>
                          <TextField
                            type="file"
                            label="Career Poster"
                            name="client_image"
                            focused
                            onChange={(e) => {
                              this.setState({
                                client_image: e.target.files[0],
                              });
                            }}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid>

                        <Grid item sm={6}>
                          <img src={Constants.imageDomain + this.state.client_image} />
                        </Grid>

                        {/* <Grid item sm={6}>
                          <TextField
                            type="text"
                            label="Testimonial"
                            name="testimonial"
                            onChange={this.handleInput}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Grid> */}
                      </Grid>

                      <Grid align="left" mt={2}>
                        <MDButton variant="contained" size="small" color="info" type="submit">
                          {this.state.loading ? "Creating..." : "Create"}
                        </MDButton>
                      </Grid>
                    </form>
                  </MDBox>
                  <>
                    <MDSnackbar
                      color="success"
                      icon="notifications"
                      title="Pro-Rider Career"
                      content={this.state.message}
                      dateTime="Just now"
                      open={this.state.notification}
                      //   close={toggleSnackbar}
                    />
                  </>
                </Card>
              </MDBox>
            </DashboardLayout>
          </div>
        </div>
      );
    }
  }
}
