import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Jobs from "./Main/Views/Jobs";
import AllApplications from "Main/Views/AllApplications";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import ApplicantProfile from "Main/Views/ApplicantProfile";
import Testimonials from "Main/Views/Testimonials";
import Subjects from "Main/Views/Subjects";
import Topics from "Main/Views/Topics";
import Levels from "Main/Views/Levels";
import Questions from "Main/Views/Questions";
import Users from "Main/Views/Users";
import TestSeries from "Main/Views/TestSeries";
import Blogs from "Main/Views/Blogs";
import WebContacts from "Main/Views/WebContacts";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "Subjects",
    key: "career",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/subjects",
    component: <Subjects />,
  },
  {
    type: "collapse",
    name: "Topics",
    key: "topics",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/topics",
    component: <Topics />,
  },
  {
    type: "collapse",
    name: "Levels",
    key: "levels",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/levels",
    component: <Levels />,
  },
  {
    type: "collapse",
    name: "Questions",
    key: "questions",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/questions",
    component: <Questions />,
  },
  {
    type: "collapse",
    name: "Test Series",
    key: "testseries",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/testseries",
    component: <TestSeries />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Blogs",
    key: "blogs",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/blogs",
    component: <Blogs />,
  },
  {
    type: "collapse",
    name: "Web Contacts",
    key: "webcontacts",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/webcontacts",
    component: <WebContacts />,
  },
  // {
  //   type: "collapse",
  //   name: "Applicants",
  //   key: "applicants",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/applicants",
  //   component: <AllApplications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Contacts",
  //   key: "contacts",
  //   icon: <Icon fontSize="small">list_view</Icon>,
  //   route: "/contacts",
  //   component: <AllContacts />,
  // },
  // {
  //   type: "collapse",
  //   name: "Pop-Up",
  //   key: "popup",
  //   icon: <Icon fontSize="small">list_view</Icon>,
  //   route: "/popup",
  //   component: <Popup />,
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
